@font-face {
    font-family: "Sora";
    src: local("Sora"),
      url("../fonts/Sora/Sora-Regular.ttf") format("truetype");
  }
  @font-face {
    font-family: "Sora-Bold";
    src: local("Sora-Bold"),
      url("../fonts/Sora/Sora-Bold.ttf") format("truetype");
  }
  .login-link {
    border-bottom: 1px solid #b8a4a4;
}
  /* Global Classes */
.sora-regular{
    font-family: Sora;
}
.sign-up-link {
  background-color: #2f95dc;
  padding: 10px 35px;
  color: white;
  border-radius: 8px;
}
.sign-up-link:hover{
  color: white;
}
.main-nav{
    margin-bottom: 20px;
}
.regular-flex {
    align-items: center;
    display: flex;
    justify-content: center;
}
/* Landing style */
.bg-landing-section-a {
    --tw-bg-opacity: 1;
    background-color: rgb(251 251 251 / var(--tw-bg-opacity));
}
.main-container-section {
    padding-left: 100px;
    padding-right: 100px;
}
.main-container {
  --tw-bg-opacity: 1;
  background-color: rgb(251 251 251 / var(--tw-bg-opacity));
  height: 100vh;
}
.content-inner-section {
    padding: 80px 20px;
}
.inner-content{
    width: 49%;
}
.section-img {
    width: 100%;
    height: 100%;
}
.section-heading {
    color: #000;
    font-family: Sora;
    font-size: 52px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
}
.section-heading-p2 {
    background: linear-gradient(97deg, #1EC1E4 18.87%, #7D55E5 87.25%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: Sora;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
}
.section-text {
    color: #000;
    font-family: 'Sora';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}
.text-right{
    text-align: right;
}
.text-42{
    font-size: 42px !important;
}
.footer-wrapper {
  --tw-bg-opacity: 1;
  background-color: rgb(251 251 251 / var(--tw-bg-opacity));
}
/* Auth screens */
.content-wrapper{
    background-image: url('../images/omniservice-auth-bg.png');
    background-color: #ffffff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .auth-logo{
    width: 70%;
  }
  .main-content-wrapper {
    display: flex;
  }
  .auth-left-content-wrapper {
    width: 50%;
    text-align: center;
  }
  .auth-right-content-wrapper {
    width: 90%;
    background-color: #ffffff;
    padding: 40px;
    border-radius: 15px;
    text-align: left;
    box-shadow: 0 0.25rem 1rem 0 rgb(47 91 234 / 13%);
  }
  .submit-btn {
    background-color: #2f95dc;
  }
  .submit-btn:focus {
    background-color: #2f95dc;
  }
  .submit-btn:hover {
    background-color: #2f95dc;
  }
  .not-a-menber {
    color: #A1A5B7;
    text-align: center;
  }
  .forgot-password{
    text-align: right;
  }
  .scroll-section {
    height: 80vh;
    overflow-y: scroll;
  }
  .h-100vh {
    height: 100vh;
}

.ml-20{
  margin-left: 20px;
}

/*display mobile when the browser window is 991px width or less*/
@media only screen and (max-width: 1200px){
    .main-container-section {
        padding-left: 15px;
        padding-right: 15px;
    }
}
@media only screen and (max-width: 768px){
    .inner-content {
        width: 100%;
        margin-bottom: 20px;
        text-align: center !important;
    }
}


.chat-search-box {
  -webkit-border-radius: 3px 0 0 0;
  -moz-border-radius: 3px 0 0 0;
  border-radius: 3px 0 0 0;
  padding: .75rem 1rem;
}

.chat-search-box .input-group .form-control {
  -webkit-border-radius: 2px 0 0 2px;
  -moz-border-radius: 2px 0 0 2px;
  border-radius: 2px 0 0 2px;
  border-right: 0;
}

.chat-search-box .input-group .form-control:focus {
  border-right: 0;
}

.chat-search-box .input-group .input-group-btn .btn {
  -webkit-border-radius: 0 2px 2px 0;
  -moz-border-radius: 0 2px 2px 0;
  border-radius: 0 2px 2px 0;
  margin: 0;
}

.chat-search-box .input-group .input-group-btn .btn i {
  font-size: 1.2rem;
  line-height: 100%;
  vertical-align: middle;
}

@media (max-width: 767px) {
  .chat-search-box {
      display: none;
  }
}


/************************************************
************************************************
                Users Container
************************************************
************************************************/

.users-container {
  position: relative;
  padding: 1rem 0;
  border-right: 1px solid #e6ecf3;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}


/************************************************
************************************************
                    Users
************************************************
************************************************/

.users {
  padding: 0;
}

.users .person {
  position: relative;
  width: 100%;
  padding: 10px 1rem;
  cursor: pointer;
  border-bottom: 1px solid #f0f4f8;
}

.users .person:hover {
  background-color: #ffffff;
  /* Fallback Color */
  background-image: -webkit-gradient(linear, left top, left bottom, from(#e9eff5), to(#ffffff));
  /* Saf4+, Chrome */
  background-image: -webkit-linear-gradient(right, #e9eff5, #ffffff);
  /* Chrome 10+, Saf5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(right, #e9eff5, #ffffff);
  /* FF3.6 */
  background-image: -ms-linear-gradient(right, #e9eff5, #ffffff);
  /* IE10 */
  background-image: -o-linear-gradient(right, #e9eff5, #ffffff);
  /* Opera 11.10+ */
  background-image: linear-gradient(right, #e9eff5, #ffffff);
}

.users .person.active-user {
  background-color: #ffffff;
  /* Fallback Color */
  background-image: -webkit-gradient(linear, left top, left bottom, from(#f7f9fb), to(#ffffff));
  /* Saf4+, Chrome */
  background-image: -webkit-linear-gradient(right, #f7f9fb, #ffffff);
  /* Chrome 10+, Saf5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(right, #f7f9fb, #ffffff);
  /* FF3.6 */
  background-image: -ms-linear-gradient(right, #f7f9fb, #ffffff);
  /* IE10 */
  background-image: -o-linear-gradient(right, #f7f9fb, #ffffff);
  /* Opera 11.10+ */
  background-image: linear-gradient(right, #f7f9fb, #ffffff);
}

.users .person:last-child {
  border-bottom: 0;
}

.users .person .user {
  display: inline-block;
  position: relative;
  margin-right: 10px;
}

.users .person .user img {
  width: 48px;
  height: 48px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
}

.users .person .user .status {
  width: 10px;
  height: 10px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  background: #e6ecf3;
  position: absolute;
  top: 0;
  right: 0;
}

.users .person .user .status.online {
  background: #9ec94a;
}

.users .person .user .status.offline {
  background: #c4d2e2;
}

.users .person .user .status.away {
  background: #f9be52;
}

.users .person .user .status.busy {
  background: #fd7274;
}

.users .person p.name-time {
  font-weight: 600;
  font-size: .85rem;
  display: inline-block;
}

.users .person p.name-time .time {
  font-weight: 400;
  font-size: .7rem;
  text-align: right;
  color: #8796af;
}

@media (max-width: 767px) {
  .users .person .user img {
      width: 30px;
      height: 30px;
  }
  .users .person p.name-time {
      display: none;
  }
  .users .person p.name-time .time {
      display: none;
  }
}


/************************************************
************************************************
                Chat right side
************************************************
************************************************/

.selected-user {
  width: 100%;
  padding: 0 15px;
  min-height: 64px;
  line-height: 64px;
  border-bottom: 1px solid #e6ecf3;
  -webkit-border-radius: 0 3px 0 0;
  -moz-border-radius: 0 3px 0 0;
  border-radius: 0 3px 0 0;
}

.selected-user span {
  line-height: 100%;
}

.selected-user span.name {
  font-weight: 700;
}

.chat-container {
  position: relative;
  padding: 1rem;
}

.chat-container li.chat-left,
.chat-container li.chat-right {
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-bottom: 40px;
}

.chat-container li img {
  width: 48px;
  height: 48px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
}

.chat-container li .chat-avatar {
  margin-right: 20px;
}

.chat-container li.chat-right {
  justify-content: flex-end;
}

.chat-container li.chat-right > .chat-avatar {
  margin-left: 20px;
  margin-right: 0;
}

.chat-container li .chat-name {
  font-size: .75rem;
  color: #999999;
  text-align: center;
}

.chat-container li .chat-text {
  padding: .4rem 1rem;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background: aliceblue;
  font-weight: 300;
  line-height: 150%;
  position: relative;
}
.chat-container li .chat-text-user {
  padding: .4rem 1rem;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background: #ebe8fe;
  font-weight: 300;
  line-height: 150%;
  position: relative;
}

.chat-container li .chat-text:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  top: 10px;
  left: -20px;
  border: 10px solid;
  border-color: transparent #ffffff transparent transparent;
}
.chat-container li .chat-text-user:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  top: 10px;
  left: -20px;
  border: 10px solid;
  border-color: transparent #ffffff transparent transparent;
}

.chat-container li.chat-right > .chat-text {
  text-align: right;
}

.chat-container li.chat-right > .chat-text:before {
  right: -20px;
  border-color: transparent transparent transparent #ffffff;
  left: inherit;
}

.chat-container li .chat-hour {
  padding: 0;
  margin-bottom: 10px;
  font-size: .75rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 15px;
}

.chat-container li .chat-hour > span {
  font-size: 16px;
  color: #9ec94a;
}

.chat-container li.chat-right > .chat-hour {
  margin: 0 15px 0 0;
}

@media (max-width: 767px) {
  .chat-container li.chat-left,
  .chat-container li.chat-right {
      flex-direction: column;
      margin-bottom: 30px;
  }
  .chat-container li img {
      width: 32px;
      height: 32px;
  }
  .chat-container li.chat-left .chat-avatar {
      margin: 0 0 5px 0;
      display: flex;
      align-items: center;
  }
  .chat-container li.chat-left .chat-hour {
      justify-content: flex-end;
  }
  .chat-container li.chat-left .chat-name {
      margin-left: 5px;
  }
  .chat-container li.chat-right .chat-avatar {
      order: -1;
      margin: 0 0 5px 0;
      align-items: center;
      display: flex;
      justify-content: right;
      flex-direction: row-reverse;
  }
  .chat-container li.chat-right .chat-hour {
      justify-content: flex-start;
      order: 2;
  }
  .chat-container li.chat-right .chat-name {
      margin-right: 5px;
  }
  .chat-container li .chat-text {
      font-size: .8rem;
  }
}

.chat-form {
  padding: 15px;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
  border-top: 1px solid white;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.card {
  border: 0;
  background: #f4f5fb;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  margin-bottom: 2rem;
  box-shadow: none;
}
.chat-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.chat-rows{
  height: 450px;
    overflow-y: scroll;
    width: 100%;
}
/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #ddd; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}